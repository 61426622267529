// src/App.js

import React from 'react';
import './App.css';
import AppRouter from './Routes/AppRouter';

function App() {
  return (
        <AppRouter />
  );
}

export default App;
