import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
// import { Polygon, MultiPolygon } from 'ol/geom';
// import * as turf from '@turf/turf';
import 'ol/ol.css';
import Feature, { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
// import { GeoJSON as OlGeoJSON } from 'ol/format'; 
import InteractiveMap from './InteractiveMap';
import { defaultStyle, selectedStyle, polygonStyle, SBFoothillsStyle, coverageStyle, WaterDistricts, intersectedStyle } from './utilities ';
import './styles.css';
import SelectionTool from '../SelectionTool/SelectionTool';
import { Style, Circle as CircleStyle, Fill } from 'ol/style';
import {Stroke} from 'ol/style';
import { fetchStreetsInSantaBarbara, selectByArea, selectByHydrant, selectByStreet } from '../SelectionTool/selectionUtils';
import { FeatureCollection } from 'geojson';
import { Map as OlMap } from 'ol';
import { FaTools, FaChevronDown, FaChevronUp  } from 'react-icons/fa';
import Legend from './leged';
import { calculateStats } from '../SelectionTool/selectionUtils';
import * as ss from 'simple-statistics'; 
import {Stats, LayerName} from './types' 
import LayerControls from './LayerControls';

// type LayerName = |'cities'|  'waterDistricts' | 'sbFoothills' | 'mainLayer' |'roadCoverage'| 'pointsLayer' ;
type StyleFunction = (feature: FeatureLike, resolution: number) => Style | Style[];
type StyleLike = Style | StyleFunction | Style[];



function WebMercatorMap() {
  const [userInput, setUserInput] = useState('');
  const [exceedingFeatures, setExceedingFeatures] = useState<Feature<Geometry>[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<Feature<Geometry> | null>(null);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [layerVisibility, setLayerVisibility] = useState({
    cities: true,
    waterDistricts: true,
    sbFoothills: false,
    mainLayer: true,
    roadCoverage: false,
    pointsLayer: true,
  });
  const [streetData, setStreetData] = useState<FeatureCollection | null>(null); // State for street data
  const [streetName, setStreetName] = useState<string>('');
  const [isStatisticsToolActive, setIsStatisticsToolActive] = useState(true);
  const [activeTool, setActiveTool] = useState<'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null>(null);
  const [interactiveMode, setInteractiveMode] = useState(false);
  const [lastActiveTool, setLastActiveTool] = useState<'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSelectionToolVisible, setIsSelectionToolVisible] = useState(false); // Control the visibility of SelectionTool
  const [highlightUnconform, setHighlightUnconform] = useState(false);
  const [excludeHighway, setExcludeHighway] = useState(true);
  const [originalFeatures, setOriginalFeatures] = useState<Feature<Geometry>[]>([]);
  const [layers, setLayers] = useState<VectorLayer<VectorSource<Feature<Geometry>>>[]>([]);
  const [map, setMap] = useState<OlMap | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isToolboxFullscreen, setIsToolboxFullscreen] = useState(false);
  const [mainLayerFeatures, setMainLayerFeatures] = useState<Feature<Geometry>[]>([]);
  const [isLayerControlVisible, setIsLayerControlVisible] = useState(true);
  const [stats, setStats] = useState({
    count: 0,
    avgLength: 0,
    conformSpacings: 0,
    pctConformity: 0,
    max: 0,
  });
  const [sbFoothillsFeatures, setSbFoothillsFeatures] = useState<Feature<Geometry>[]>([]);
  const isInitialMount = useRef(true);
  const [classBreaks, setClassBreaks] = useState<number[]>([]);
  const [colorScale, setColorScale] = useState<string[]>([]);  
  const [newVectorLayer, setNewVectorLayer] = useState<VectorLayer<VectorSource<Feature<Geometry>>>>(); 
  const initialStatsRef = useRef({
    count: 0,
    avgLength: 0,
    conformSpacings: 0,
    pctConformity: 0,
    max: 0,
  });

  const handleSetActiveTool = (tool: 'rectangle' | 'polygon' | 'byHydrant' | 'byStreet' | 'sidebar' | null) => {
    setActiveTool(tool);
    // setIsStatisticsToolActive(tool !== null && tool !== 'sidebar');
    if (tool === 'sidebar') {
      setIsStatisticsToolActive(false);
    } else {
      setIsStatisticsToolActive(true);
    }
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const handleLayerVisibilityChange = (layerName: LayerName) => {
    setLayerVisibility((prevVisibility) => ({
      ...prevVisibility,
      [layerName]: !prevVisibility[layerName],
    }));
  };

  const filterFeaturesByLength = () => {
    const segmentLayer = layers[3];
    const source = segmentLayer?.getSource();
    if (source) {
      setPopupVisibility(false);
      console.log("seting pop up visibility to false")
      const allFeatures = source.getFeatures() as Feature<Geometry>[];
      allFeatures.forEach((feature) => {
        const intersects = feature.get('intersects');
        if (intersects !== 1) { // Only reset style for non-intersected features
          feature.setStyle(defaultStyle);
        }
      });

      const matchedFeatures = allFeatures.filter((feature) => {
        const length = parseFloat(feature.get('length'));
        return length > parseFloat(userInput);
      });

      matchedFeatures.forEach((feature) => feature.setStyle(selectedStyle));
      setExceedingFeatures(matchedFeatures);

      if (matchedFeatures.length > 0) {
        setIsSidebarOpen(true);
      }
    }
  };

  const clearSelection = () => {
    setUserInput('');
    setExceedingFeatures([]);
    setSelectedFeature(null);
    setHighlightUnconform(false)
    setStats(initialStatsRef.current );
    if (layers[3]) {
      const source = layers[3].getSource();
      if (source) {
        const allFeatures = source.getFeatures();
        allFeatures.forEach((feature) => {
          const intersects = feature.get('intersects');
          if (intersects === 0) { // Only reset style for non-intersected features
            feature.setStyle(defaultStyle);
          }
          else{
            feature.setStyle(choroplethStyleFunction)
          }
        });
      }
    }
  };

  // New effect to filter unconform features
  useEffect(() => {
    const segmentLayer = layers[3];
    const source = segmentLayer?.getSource();

    if (!source) return;

    if (highlightUnconform) {
      // Process highlighting unconform features
      const allFeatures = source.getFeatures() as Feature<Geometry>[];
      const unconformFeatures = allFeatures.filter(
        (feature) => feature.get('non_compli') === 1
      );

      unconformFeatures.forEach((feature) => feature.setStyle(selectedStyle));
      setExceedingFeatures(unconformFeatures);

      if (unconformFeatures.length > 0) {
        setIsSidebarOpen(true);
      }
    } else {
      if (isInitialMount.current) {
        // Skip clearing selection on initial mount
        isInitialMount.current = false;
      } else {
        clearSelection(); // Only clear selection if not initial mount
      }
    }
  }, [highlightUnconform, layers]);

//Filter out highway and freeway


useEffect(() => {
  const segmentLayer = layers[3];
  const source = segmentLayer?.getSource();

  if (source) {
    // Step 1: Preserve original styles
    source.getFeatures().forEach((feature) => {
      const currentStyle = feature.getStyle(); // StyleLike | undefined
      originalStyles.set(feature, currentStyle as StyleLike | undefined); // Type assertion to match StyleLike
    });

    // Step 2: Clear current features
    source.clear();

    // Step 3: Add filtered or all features back
    if (excludeHighway) {
      const nonHighwayFeatures = originalFeatures.filter((feature) =>
        feature.get('highway') === 'other' || feature.get('highway') === 'highway'
      );
      source.addFeatures(nonHighwayFeatures); // Add non-highway features only
    } else {
      source.addFeatures(originalFeatures); // Add all features back
    }

    // Step 4: Reapply original styles
    source.getFeatures().forEach((feature) => {
      const savedStyle = originalStyles.get(feature);

      if (savedStyle) {
        if (typeof savedStyle === 'function') {
          // Apply as a StyleFunction
          feature.setStyle(savedStyle as StyleFunction);
        } else if (Array.isArray(savedStyle)) {
          // Wrap Style[] in a function to match StyleFunction signature
          feature.setStyle(() => savedStyle);
        } else {
          // Apply as a single Style
          feature.setStyle(savedStyle as Style);
        }
      }
    });
  }
}, [excludeHighway, layers, originalFeatures]);

// Define the StyleFunction correctly
const originalStyles = new Map<FeatureLike, StyleLike | undefined>();







  const toggleInteractiveMode = () => {
    if (interactiveMode) {
      setActiveTool(lastActiveTool);
    } else {
      setLastActiveTool(activeTool);
      setActiveTool(null);
    }
    setInteractiveMode(!interactiveMode);
  };

  const toggleLayerControl = () => {
    // console.log('Toggling Layer Control:', isLayerControlVisible); // Check current state
    setIsLayerControlVisible((prev) => !prev);
  };


  const choroplethStyleFunction = useCallback(
    (feature: FeatureLike): Style => {


      const intersects = feature.get('intersects');
      const highwayorNot = feature.get('highway')
      if (intersects !== 1 || highwayorNot=='freeway') {
        // Return null to use default style or define a different style
        return defaultStyle; // Or return defaultStyle;
      }
  
      const severity = parseFloat(feature.get('severity') || '0');
      if (isNaN(severity)) {
        return defaultStyle;
      }
  
      let classIndex = classBreaks.length - 2; // Default to the last class
      for (let i = 1; i < classBreaks.length; i++) {
        if (severity <= classBreaks[i]) {
          classIndex = i - 1;
          break;
        }
      }
  
      // console.log(`Class Index: ${classIndex} for Severity: ${severity}`);
  
      const fillColor = colorScale[classIndex] || 'rgba(135, 66, 245)'; // Default to purple if out of range
  
      return new Style({
        stroke: new Stroke({
          color: fillColor,
          width: 3,
        }),
      });
    },
    [classBreaks, colorScale]
  );
  

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response3 = await fetch('/cities.geojson');
        if (!response3.ok) {
          throw new Error('Failed to fetch cities.geojson');
        }
        const data3 = await response3.json();

        const citiesSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data3),
        });
        const newCities = new VectorLayer({
          source: citiesSource,
          style: polygonStyle,
          visible: true,
          zIndex: 0,
          properties: { name: 'cities' },
        });

        const response4 = await fetch('/water_districts.geojson');
        if (!response4.ok) {
          throw new Error('Failed to fetch water_districts.geojson');
        }
        const data4 = await response4.json();

        const WaterSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data4),
        });
        const newWaterSource = new VectorLayer({
          source: WaterSource,
          style: WaterDistricts,
          visible: true,
          zIndex: 0,
          properties: { name: 'waterDistricts' },
        });

        const response5 = await fetch('/coverage.geojson');
        if (!response5.ok) {
          throw new Error('Failed to fetch water_districts.geojson');
        }
        const data5 = await response5.json();

        const Coverage = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data5),
        });
        const newCoverage = new VectorLayer({
          source: Coverage,
          style: coverageStyle,
          visible: true,
          zIndex: 0,
          properties: { name: 'Coverage' },
        });

        const response6 = await fetch('/SB_foothills.geojson');
        if (!response6.ok) {
          throw new Error('Failed to fetch SB_foothills.geojson');
        }
        const data6 = await response6.json();

        const SBFoothillSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data6),
        });
        const newSBFoothills = new VectorLayer({
          source: SBFoothillSource,
          style: SBFoothillsStyle,
          visible: layerVisibility.sbFoothills,
          zIndex: 0,
          properties: { name: 'sbFoothills' }, 
        });


        const response = await fetch('/edge_approach.geojson');
      if (!response.ok) {
        throw new Error('Failed to fetch edge_approach.geojson');
      }
      const data = await response.json();

      // Load the GeoJSON features
      const vectorSource = new VectorSource<Feature<Geometry>>({
        features: new GeoJSON().readFeatures(data),
      });

      // Loop through the features and apply styles based on the "intersects" field
      // vectorSource.getFeatures().forEach((feature) => {
      //   const intersects = feature.get('intersects'); // Get the "intersects" property
      //   // console.log(`Feature intersects value: ${intersects}`);
      //   if (intersects === 1) {
      //     feature.setStyle(intersectedStyle); // Apply intersected style if "intersects" is true
      //   } else {
      //     feature.setStyle(defaultStyle); // Apply default style otherwise
      //   }
      // });

      const intersectedFeatures = vectorSource
        .getFeatures()
        .filter(
          (feature) =>
            feature.get('intersects') === 1 &&
            (feature.get('highway') === 'other' || feature.get('highway') === 'highway')
        );
      const initialStats = calculateStats(intersectedFeatures);
      setStats(initialStats);
      initialStatsRef.current = initialStats;

      // Extract severity values 
      const severityValues = intersectedFeatures
        .map((feature) => parseFloat(feature.get('severity') || '0'))
        .filter((value) => !isNaN(value));

      // Calculate natural breaks (Jenks) for 5 classes (you can adjust the number)
      // const breaks = ss.jenks(severityValues, 5);
      // console.log('Jenks Natural Breaks:', breaks);
      // setClassBreaks(breaks);
      // Ensure severityValues is sorted
          const sortedSeverityValues = severityValues.slice().sort((a, b) => a - b);

          // Compute quantile breaks at 0%, 20%, 40%, 60%, 80%, and 100%
          // const probabilities = [0, 0.2, 0.4, 0.6, 0.8, 1];
          // const breaks = probabilities.map((p) => ss.quantileSorted(sortedSeverityValues, p));
          const manualBreaks = [0, 0, 100, 500, 2000, 5000, Number.POSITIVE_INFINITY];
          setClassBreaks(manualBreaks);

          // console.log('Quantile Breaks:', breaks);
          setClassBreaks(manualBreaks);


      // Define color scale from orange to red
      const colors = Array.from({ length: 6 }, (_, i) => {
        const r = 255;
        const g = Math.round(255 - (255 * (i / 4))); // Decrease green from 255 to 0
        const b = 0;
        return `rgb(${r}, ${g}, ${b})`;
      });
      setColorScale(colors);

      
      // console.log('Severity Values:', severityValues);

      setOriginalFeatures(vectorSource.getFeatures());


      const vectorLayer = new VectorLayer({
        source: vectorSource,
        visible: true,
        updateWhileAnimating: true,
        updateWhileInteracting: true,
        properties: { name: 'mainLayer' },
      });
      
    
      setNewVectorLayer(vectorLayer);



        const response2 = await fetch('/hydrants_reprojected.geojson');
        if (!response2.ok) {
          throw new Error('Failed to fetch hydrants_reprojected.geojson');
        }
        const data2 = await response2.json();

        const pointsSource = new VectorSource<Feature<Geometry>>({
          features: new GeoJSON().readFeatures(data2),
        });
        const newPointsLayer = new VectorLayer({
          source: pointsSource,
          style: new Style({
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({
                color: '#3399ff',
              }),
            }),
          }),
          visible: true,
          properties: { name: 'PointsLayer' },
        });

        
        setLayers([newCities, newWaterSource, newSBFoothills, vectorLayer, newCoverage, newPointsLayer]);

        const streetData = await fetchStreetsInSantaBarbara();
        setStreetData(streetData); // Store street data
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (layers.length > 0) {
      layers[0].setVisible(layerVisibility.cities);
      layers[1].setVisible(layerVisibility.waterDistricts);
      layers[2].setVisible(layerVisibility.sbFoothills)
      layers[3].setVisible(layerVisibility.mainLayer);
      layers[4].setVisible(layerVisibility.roadCoverage);
      layers[5].setVisible(layerVisibility.pointsLayer);
    }
  }, [layerVisibility, layers]);

// Whenever features might change
useEffect(() => {
  if (layers.length > 0) {
    const sbLayer = layers.find(layer => layer.get('name') === 'sbFoothills');
    const sbSource = sbLayer?.getSource();
    const mainLayer = layers.find(layer => layer.get('name') === 'mainLayer');
    const source = mainLayer?.getSource();
    
    if (sbSource && source) {
      const handleChange = () => {
        setSbFoothillsFeatures(sbSource.getFeatures());
        setMainLayerFeatures(source.getFeatures());
      };

      sbSource.on('change', handleChange);
      
      // Trigger the handler initially to set features
      handleChange();
      
      // Cleanup on unmount or when layers change
      return () => {
        sbSource.un('change', handleChange);
      };
    }
  }
}, [layers]);

useEffect(() => {
  if (newVectorLayer && classBreaks.length > 0 && colorScale.length > 0) {
    newVectorLayer.setStyle(choroplethStyleFunction);
    // Force re-render
    newVectorLayer.getSource()?.changed();
    newVectorLayer.changed();
  }
}, [choroplethStyleFunction, newVectorLayer]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        console.log("it is a mobile device");
        setIsMobile(true); // This will be true in the next render
        setIsLayerControlVisible(false);
      } else {
        setIsMobile(false); // Reset state for larger screens
        setIsLayerControlVisible(true);
      }
    };
  
// Call the handler once to check initial size
handleResize();

// Add event listener for window resize
window.addEventListener('resize', handleResize);


// Cleanup listener on component unmount
return () => window.removeEventListener('resize', handleResize);
}, []);
  
const legendItems = useMemo(() => {
  if (classBreaks.length > 1 && colorScale.length > 0) {
    return classBreaks.slice(0, -1).map((breakPoint, index) => {
      const range = `${breakPoint} - ${classBreaks[index + 1]}`;
      return {
        color: colorScale[index],
        range,
      };
    });
  }
  return [];
}, [classBreaks, colorScale]);

const sbFoothillsStats: Stats = useMemo(() => {
  if (!layerVisibility.sbFoothills) {
    console.log('useMemo: SB Foothills layer is not visible. Returning default stats.');
    return {
      count: 0,
      avgLength: 0,
      conformSpacings: 0,
      pctConformity: 0,
      max: 0,
    };
  }

  if (!sbFoothillsFeatures || sbFoothillsFeatures.length === 0) {
    console.warn('useMemo: SB Foothills features are not loaded yet.');
    return {
      count: 0,
      avgLength: 0,
      conformSpacings: 0,
      pctConformity: 0,
      max: 0,
    };
  }

  // Filter features from the main layer where inter_sb_foothills is true
  const sbFeatures = mainLayerFeatures.filter(
    (feature) => feature.get('inter_sb_foothills') === true
  );

  console.log(`useMemo: Found ${sbFeatures.length} features in SB Foothills.`);

  // Calculate statistics using the existing utility function
  const calculatedStats = calculateStats(sbFeatures);

  console.log('useMemo: Calculated SB Foothills Stats:', calculatedStats);

  // Ensure calculatedStats is always defined
  return calculatedStats || {
    count: 0,
    avgLength: 0,
    conformSpacings: 0,
    pctConformity: 0,
    max: 0,
  };
}, [layerVisibility.sbFoothills, mainLayerFeatures]);
  




  return (
    <div className="relative h-[90vh] w-screen">
        <InteractiveMap
          layers={layers}
          layerVisibility={layerVisibility}
          currentlySelectedFeature={selectedFeature}
          changeSelectedFeature={setSelectedFeature}
          isPopupVisible={isPopupVisible}
          setPopupVisible={setPopupVisibility}
          selectByArea={(geometry) => selectByArea(layers, geometry, excludeHighway)}
          selectByHydrant={(hydrantId) => selectByHydrant(layers, hydrantId, excludeHighway)}
          selectByStreet={() =>
            selectByStreet(layers, streetName, streetData!, map!, excludeHighway)
          }
          activeTool={activeTool}
          setMapRef={setMap}
          stats={stats}
          sbFoothillsStats={sbFoothillsStats}
          setStats={setStats}
          showStatistics={isStatisticsToolActive}
          interactiveMode={interactiveMode}
          streetName={streetName}
          handleLayerVisibilityChange={handleLayerVisibilityChange}
          excludeHighway={excludeHighway}
          setExcludeHighway={setExcludeHighway}
          toggleInteractiveMode={toggleInteractiveMode}
          setIsSelectionToolVisible={setIsSelectionToolVisible}
          isMobile={isMobile}
          toggleLayerControl={toggleLayerControl}
          isLayerControlVisible={isLayerControlVisible}
          />
      {/* Selection Tool Container */}
      <div
        className={`selection-tool-container ${
          isMobile
            ? `fixed inset-0 z-50 bg-white overflow-y-scroll transition-transform duration-300 ${
                isSelectionToolVisible ? 'translate-y-0' : 'translate-y-full'
              }`
            : 'relative'
        }`}
      >

        <div className=' bottom-2 fixed z-50 overflow-hidden'>
          {/* Legend */}
      <Legend items={legendItems} />
        </div>
        
        {/* Minimize/Expand Button */}
        {isMobile && (
          <button
            onClick={() => setIsSelectionToolVisible(!isSelectionToolVisible)}
            className="absolute top-0 right-4 bg-white p-2 rounded-full z-50"
          >
            {isSelectionToolVisible ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        )}
  
        {/* Render SelectionTool here */}
        {isSelectionToolVisible && (
          <SelectionTool
            isMobile={isMobile} 
            selectByArea={(type) => {
              setInteractiveMode(false);
              handleSetActiveTool(type);
            }}
            selectByStreet={(streetName) => {
              setStreetName(streetName);
              setInteractiveMode(false);
              handleSetActiveTool('byStreet');
              return selectByStreet(layers, streetName, streetData!, map!, excludeHighway)
                .then((stats) => {
                  setStats(stats || { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
                  return stats;
                });
            }}
            selectByHydrant={() => {
              setInteractiveMode(false);
              handleSetActiveTool('byHydrant');
            }}
            selectBySidebar={() => {
              setInteractiveMode(false);
              handleSetActiveTool('sidebar');
            }}
            clearSelection={clearSelection}
            streetData={streetData}
            userInput={userInput}
            onInputChange={handleInputChange}
            onSubmit={filterFeaturesByLength}
            features={exceedingFeatures}
            selectedFeature={selectedFeature}
            onSelectFeature={setSelectedFeature}
            hidePopup={() => setPopupVisibility(false)}
            setIsSelectionToolVisible={setIsSelectionToolVisible}
            highlightUnconform={highlightUnconform}
            setHighlightUnconform={setHighlightUnconform}
            map1={map}
            setStats={setStats}
          />
        )}
      </div>
    </div>
  );
  
  // Export the WebMercatorMap component
} 

export default WebMercatorMap;

  
