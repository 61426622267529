import React from 'react';
import logo from './wrilogo_1.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-blue shadow-md py-4 md:py-10 bg:py-20 header w-full z-50">
      <div className="flex items-center justify-between px-4 md:px-6 lg:px-8 w-full">
        <a href="https://wri.ucsb.edu/">
          <img src={logo} alt="Logo" className="h-7" />
        </a>
        <div className="flex items-center space-x-4 ml-auto">
          <nav className="flex space-x-4 text-white">
            <NavLink to="/" className="hover:text-yellow hover:font-bold">Map</NavLink>
            {/* <NavLink to="/Neighbors" className="hover:text-yellow hover:font-bold">Neighborhoods</NavLink> */}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

