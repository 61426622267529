import React, { useState } from 'react';
import Draggable from 'react-draggable';
import FilterInput from './FilterInput';
import FeatureList from './FeatureList';
import { Map as OlMap } from 'ol';


interface SidebarProps {
  isMobile: boolean,
  userInput: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  features: any[];
  selectedFeature: any;
  onSelectFeature: (feature: any) => void;
  hidePopup: () => void;
  clearSelection: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children?: React.ReactNode; 
  highlightUnconform: boolean; // New prop to track checkbox state
  setHighlightUnconform: React.Dispatch<React.SetStateAction<boolean>>; // New prop to update checkbox state
  map: OlMap | null;
  setIsSelectionToolVisible:(isVisible: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
    isMobile,
    userInput,
    onInputChange,
    onSubmit,
    features,
    selectedFeature,
    onSelectFeature,
    hidePopup,
    clearSelection,
    isOpen,
    setIsOpen,
    children,
    highlightUnconform,
    setHighlightUnconform,
    map,
    setIsSelectionToolVisible
  }) => {
    return isOpen ? (
      <>
        {isMobile ? (
          <div className="fixed inset-0 z-50 bg-white p-4 overflow-y-scroll shadow-lg">
            <div className="relative">
              <button
                onClick={() => {setIsOpen(false); 
                  setIsSelectionToolVisible(false)}}
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              >
                ✕
              </button>
              <h2 className="text-xl font-bold mb-4">Side Bar</h2>
            </div>
            {children && <div className="mb-4">{children}</div>}
            <FilterInput 
              value={userInput} 
              onChange={onInputChange} 
              onSubmit={onSubmit}
              onClear={clearSelection}
            />
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                id="highlightUnconform"
                checked={highlightUnconform}
                onChange={(e) => setHighlightUnconform(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="highlightUnconform" className="text-lg">Highlight Unconform Spacings</label>
            </div>
            {features.length > 0 && (
              <>
                <h2 className="text-xl font-bold mb-4">Highlighted Features</h2>
                <FeatureList
                  features={features}
                  currentlySelectedFeature={selectedFeature}
                  changeSelectedFeature={onSelectFeature}
                  hidePopup={hidePopup}
                  map={map!}
                />
              </>
            )}
          </div>
        ) : (
          <Draggable handle=".handle">
            <div className="fixed top-16 left-4 m-4 w-64 bg-white bg-opacity-70 p-4 rounded-lg shadow-lg z-50">
              <div className="handle cursor-move">
                <button
                  onClick={() => setIsOpen(false)}
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                >
                  ✕
                </button>
                <h2 className="text-xl font-bold mb-4">Side Bar</h2>
              </div>
              {children && <div className="mb-4">{children}</div>}
              <FilterInput 
                value={userInput} 
                onChange={onInputChange} 
                onSubmit={onSubmit}
                onClear={clearSelection}
              />
              <div className="mt-4 flex items-center">
                <input
                  type="checkbox"
                  id="highlightUnconform"
                  checked={highlightUnconform}
                  onChange={(e) => setHighlightUnconform(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="highlightUnconform" className="text-lg">Highlight Unconform Spacings</label>
              </div>
              {features.length > 0 && (
                <>
                  <h2 className="text-xl font-bold mb-4">Highlighted Features</h2>
                  <FeatureList
                    features={features}
                    currentlySelectedFeature={selectedFeature}
                    changeSelectedFeature={onSelectFeature}
                    hidePopup={hidePopup}
                    map={map!}
                  />
                </>
              )}
            </div>
          </Draggable>
        )}
      </>
    ) : (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed top-[20v] left-4 m-4 bg-white bg-opacity-70 p-2 rounded-lg shadow-lg z-50"
      >
        Side Bar
      </button>
    );
  }    
  
export default Sidebar;