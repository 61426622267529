import React from 'react';

interface FilterInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onClear: () => void; // Add a prop for the clear action
}

const FilterInput: React.FC<FilterInputProps> = ({ value, onChange, onSubmit, onClear }) => {
  return (
    <div className="text-gray-900 p-1">
      <label className="text-lg font-bold mb-2 block">
        Highlight segments over:
        <input
          type="number"
          value={value}
          onChange={onChange}
          className="ml-2 p-1 border-2 border-yellow rounded bg-transparent text-gray-600 w-full"
        />
        feet
      </label>
      <div className="mt-4 flex space-x-2">
        <button
          onClick={onSubmit}
          className="py-2 px-4 text-lg text-black border-yellow border-2 rounded shadow hover:bg-gray-300 hover:text-white"
        >
          Highlight
        </button>
        <button
          onClick={onClear} // Add onClick handler for clear action
          className="py-2 px-4 text-lg text-black border-yellow border-2 rounded shadow hover:bg-gray-300 hover:text-white"
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default FilterInput;