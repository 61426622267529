import React, { useState, useMemo, useCallback } from 'react';
import Draggable from 'react-draggable';
import Sidebar from '../Sidebar'; 
import { FeatureCollection } from 'geojson';
import { Map as OlMap } from 'ol';
import { debounce } from 'lodash';

interface SelectionToolProps {
  isMobile: boolean;
  clearSelection: () => void;
  selectByArea: (type: 'rectangle' | 'polygon') => void;
  selectByStreet: (streetName: string) => Promise<{ count: number; avgLength: number; conformSpacings: number; pctConformity: number; max: number } | null>;
  selectByHydrant: () => void;
  selectBySidebar: () => void;
  streetData: FeatureCollection | null;
  userInput: string; 
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  onSubmit: () => void; 
  features: any[]; 
  selectedFeature: any; 
  onSelectFeature: (feature: any) => void; 
  hidePopup: () => void;
  setIsSelectionToolVisible: (isVisible: boolean) => void;
  highlightUnconform: boolean;  // Add this line
  setHighlightUnconform: React.Dispatch<React.SetStateAction<boolean>>;
  map1: OlMap | null;
  setStats: React.Dispatch<React.SetStateAction<{
    count: number;
    avgLength: number;
    conformSpacings: number;
    pctConformity: number;
    max: number;
  }>>;
}

const SelectionTool: React.FC<SelectionToolProps> = ({
  isMobile,
  clearSelection,
  selectByArea,
  selectByStreet,
  selectByHydrant,
  selectBySidebar,
  streetData,
  userInput,
  onInputChange,
  onSubmit,
  features,
  selectedFeature,
  onSelectFeature,
  hidePopup,
  setIsSelectionToolVisible,
  highlightUnconform,
  setHighlightUnconform,
  map1,
  setStats
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false); // New state for sidebar visibility
  const [streetName, setStreetName] = useState('');

  const suggestions = useMemo(() => {
    if (!streetData) return [];
    const normalizedInput = streetName.toLowerCase();
    return streetData.features
      .map((feature) => feature.properties?.name)
      .filter((name) => name && name.toLowerCase().includes(normalizedInput));
  }, [streetName, streetData]);

  // const debouncedStreetSelection = useCallback(
  //   debounce((name: string) => {
  //     selectByStreet(name);
  //   }, 500), // Adjust the debounce delay as necessary
  //   [selectByStreet]
  // );

  const handleStreetInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setStreetName(name);
    // selectByStreet(name)
    clearSelection(); // Clear previous selection when a new street name is entered
  };

  const handleBackButtonClick = () => {
    setIsSidebarVisible(false);
  };
  
  const handleSidebarActivation = () => {
    selectBySidebar();
    setIsSidebarVisible(true);
    // Additional logic for when the sidebar is activated
  };


  if (isSidebarVisible) {
    return (
      <Sidebar
        isMobile={isMobile}
        userInput={userInput}
        onInputChange={onInputChange}
        onSubmit={onSubmit}
        features={features}
        selectedFeature={selectedFeature}
        onSelectFeature={onSelectFeature}
        hidePopup={hidePopup}
        clearSelection={clearSelection}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        highlightUnconform={highlightUnconform} // Pass the highlight state
        setHighlightUnconform={setHighlightUnconform} // 
        map = {map1}
        setIsSelectionToolVisible = {setIsSelectionToolVisible}
      >
        <button onClick={handleBackButtonClick} className="mb-4 text-gray-600 hover:text-gray-800">
          ← Back to Tools
        </button>
      </Sidebar>
    );
  }

  return isOpen ? (
    <>
      {isMobile ? (
        <div className="fixed inset-0 z-40 bg-white overflow-y-scroll p-4 rounded-lg shadow-lg">
          <div className="relative">
            {/* <button
              onClick={() => {
                setIsOpen(false);
                setIsSelectionToolVisible(false); // Update the parent component state when closing
              }}
              className="absolute top-2 right-2 text-gray-300 hover:text-gray-800 text-bold"
            >
              ✕
            </button> */}
            <h2 className="text-xl font-bold mb-4">Selection Tools</h2>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-2">Select by Area</h3>
            <button
              onClick={() => {selectByArea('polygon'); 
              setIsSelectionToolVisible(false);
              }}
              className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
            >
              Select by Polygon
            </button>
            {/* <button
              onClick={() => selectByArea('rectangle')}
              className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
            >
              Select by Rectangle
            </button> */}
          </div>
          <div>
            <h3 className="text-lg font-bold mb-2">Select by Street</h3>
            <input
              type="text"
              value={streetName}
              onChange={handleStreetInputChange}
              className="mb-2 p-2 border rounded w-full"
              placeholder="Enter street name"
              list="street-suggestions"
            />
            <datalist id="street-suggestions">
              {suggestions.map((suggestion, index) => (
                <option key={index} value={suggestion} />
              ))}
            </datalist>
            <button
              onClick={() => {
                clearSelection(); // Clear previous selection when a new street name is entered
                setIsSelectionToolVisible(false);
                selectByStreet(streetName).then((stats) => {
                  // Set stats after selection
                  setStats(stats || { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
                });
              }}
              className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
            >
              Select by Street
            </button>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-2">Select by hydrant and see neighbors</h3>
            <p className="text-gray-600 mb-2">Click to select a hydrant on the map</p>
            <button
              onClick={()=>{selectByHydrant();
                setIsSelectionToolVisible(false);
              }}
              className="mb-2 py-2 px-4 text-gray-600 text-left bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
            >
              Select by hydrant
            </button>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-2">Select by threshold or see inconformities</h3>
            <button
              onClick={handleSidebarActivation}
              className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
            >
              Select by threshold/standard
            </button>
          </div>
          <div>
            <button
              onClick={clearSelection}
              className="mb-2 py-2 px-4 text-white bg-red-500 rounded shadow hover:bg-red-700"
            >
              Clear Selection
            </button>
          </div>
        </div>
      ) : (
        <Draggable handle=".handle">
          <div className="fixed top-16 left-4 m-4 w-64 bg-white bg-opacity-70 p-4 rounded-lg shadow-lg z-50">
            <div className="handle cursor-move">
              <button
                onClick={() => {
                  setIsOpen(false);
                  setIsSelectionToolVisible(false); // Update the parent component state when closing
                }}
                className="absolute top-2 right-2 text-gray-300 hover:text-gray-800 text-bold"
              >
                ✕
              </button>
              <h2 className="text-xl font-bold mb-4">Selection Tools</h2>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-2">Select by Area</h3>
              <button
                onClick={() => selectByArea('polygon')}
                className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
              >
                Select by Polygon
              </button>
              <button
                onClick={() => selectByArea('rectangle')}
                className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
              >
                Select by Rectangle
              </button>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-2">Select by Street</h3>
              <input
                type="text"
                value={streetName}
                onChange={handleStreetInputChange}
                className="mb-2 p-2 border rounded w-full"
                placeholder="Enter street name"
                list="street-suggestions"
              />
              <datalist id="street-suggestions">
                {suggestions.map((suggestion, index) => (
                  <option key={index} value={suggestion} />
                ))}
              </datalist>
              <button
                onClick={() => {
                  clearSelection(); // Clear previous selection when a new street name is entered
                  selectByStreet(streetName).then((stats) => {
                    // Set stats after selection
                    setStats(stats || { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 });
                  });
                }}
                className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
              >
                Select by Street
              </button>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-2">Select by hydrant and see neighbors</h3>
              <p className="text-gray-600 mb-2">Click to select a hydrant on the map</p>
              <button
                onClick={selectByHydrant}
                className="mb-2 py-2 px-4 text-gray-600 text-left bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
              >
                Select by hydrant
              </button>
            </div>
            <div>
              <h3 className="text-lg font-bold mb-2">Select by threshold or see inconformities</h3>
              <button
                onClick={handleSidebarActivation}
                className="mb-2 py-2 px-4 text-gray bg-blue-500 rounded shadow hover:bg-blue hover:text-white"
              >
                Select by threshold/standard
              </button>
            </div>
            <div>
              <button
                onClick={clearSelection}
                className="mb-2 py-2 px-4 text-white bg-red-500 rounded shadow hover:bg-red-700"
              >
                Clear Selection
              </button>
            </div>
          </div>
        </Draggable>
      )}
    </>
  ) : null;
};

export default SelectionTool;