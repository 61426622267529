// src/components/WebMercatorMap/StatisticsDisplay.tsx

import React from 'react';

interface Statistics {
  count: number;
  avgLength: number;
  conformSpacings: number;
  pctConformity: number;
  max: number;
}

interface StatisticsDisplayProps {
  title: string; // New title prop
  stats: Statistics;
}

const StatisticsDisplay: React.FC<StatisticsDisplayProps> = ({ title, stats }) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md overflow-y-auto w-full">
      {/* Title */}
      <h2 className="text-xl font-semibold mb-4 text-center">{title}</h2>

      {/* Statistics Table */}
      <table className="table-auto w-full text-left border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-pink-100">
              Statistic
            </th>
            <th className="border border-gray-300 px-4 py-2 bg-pink-100">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Table Rows */}
          <tr>
            <td className="border border-gray-300 px-4 py-2">Count</td>
            <td className="border border-gray-300 px-4 py-2">{stats.count}</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Average Length</td>
            <td className="border border-gray-300 px-4 py-2">{stats.avgLength.toFixed(2)} feet</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Conform Spacings</td>
            <td className="border border-gray-300 px-4 py-2">{stats.conformSpacings}</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">% Conformity</td>
            <td className="border border-gray-300 px-4 py-2">{stats.pctConformity.toFixed(2)}%</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Max Length</td>
            <td className="border border-gray-300 px-4 py-2">{stats.max.toFixed(2)} feet</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StatisticsDisplay;


