import React from 'react';

interface LegendItem {
  color: string;
  range: string;
}

interface LegendProps {
  items: LegendItem[];
}

const Legend: React.FC<LegendProps> = ({ items }) => {
  if (items.length === 0) {
    return null; // Don't render if no items
  }

  return (
    <div className="legend relative overflow-hidden bottom-4 left-4 bg-white bg-opacity-80 p-4 mr-2 mt-2 rounded-lg shadow-lg text-sm font-semibold">
      <div className="mb-2 text-center">Violations</div>
      {items.map((item, index) => (
        <div key={index} className="flex items-center mb-1">
          <div
            className="w-4 h-4 mr-2"
            style={{
              backgroundColor: item.color,
              stroke: '1px solid #333333',
              width: 3,
              
            }}
          ></div>
          <span>{item.range}</span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
