// LayerControls.jsx
import React from 'react';
import { FaTools } from 'react-icons/fa';

type LayerName = |'cities'|  'waterDistricts' | 'sbFoothills' | 'mainLayer' |'roadCoverage'| 'pointsLayer' ;

interface LayerControlsProps {
    layerVisibility: Record<LayerName, boolean>;
    handleLayerVisibilityChange: (layerName: LayerName) => void;
    excludeHighway: boolean;
    setExcludeHighway: React.Dispatch<React.SetStateAction<boolean>>;
    interactiveMode: boolean;
    toggleInteractiveMode: () => void;
    setIsSelectionToolVisible: React.Dispatch<React.SetStateAction<boolean>>;
  }

const LayerControls: React.FC<LayerControlsProps> = ({
  layerVisibility,
  handleLayerVisibilityChange,
  excludeHighway,
  setExcludeHighway,
  interactiveMode,
  toggleInteractiveMode,
  setIsSelectionToolVisible,
}) => (
  <div className='realtive w-full'>
    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.mainLayer}
        onChange={() => handleLayerVisibilityChange('mainLayer')}
        className="mr-2"
      />
      Spacings
    </label>
    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.pointsLayer}
        onChange={() => handleLayerVisibilityChange('pointsLayer')}
        className="mr-2"
      />
      Hydrants
    </label>
    <label className="block mb-2">
      <input
        type="checkbox"
        checked={excludeHighway}
        onChange={() => setExcludeHighway((prev) => !prev)}
        className="mr-2"
      />
      Exclude Freeway
    </label>

    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.cities}
        onChange={() => handleLayerVisibilityChange('cities')}
        className="mr-2"
      />
      Cities
    </label>

    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.waterDistricts}
        onChange={() => handleLayerVisibilityChange('waterDistricts')}
        className="mr-2"
      />
      Water Districts
    </label>
    
    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.sbFoothills}
        onChange={() => handleLayerVisibilityChange('sbFoothills')}
        className="mr-2"
      />
      Santa Barbara Foothills
    </label>

    <label className="block mb-2">
      <input
        type="checkbox"
        checked={layerVisibility.roadCoverage}
        onChange={() => handleLayerVisibilityChange('roadCoverage')}
        className="mr-2"
      />
      Hydrant Coverage
    </label>

    <button
      onClick={toggleInteractiveMode}
      className={`mr-2 mt-1 mb-2 text-white rounded w-full ms:w-auto shadow text-sm md:text-auto ${
        interactiveMode ? 'bg-yellow' : 'bg-blue'
      } hover:bg-blue`}
    >
      {interactiveMode ? 'Disable Interactive Mode' : 'Enable Interactive Mode'}
    </button>
    <button
      onClick={() => setIsSelectionToolVisible((prev) => !prev)}
      className="flex items-center space-x-2 bg-white w-full md:w-auto text-black rounded shadow hover:bg-gray-300 p-2"
    >
      <FaTools />
      <span className='text-sm md:text-auto'>Selection Toolbox</span>
    </button>
  </div>
);

export default LayerControls;
