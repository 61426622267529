
import { Vector as VectorSource } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Feature } from 'ol';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import MultiLineString from 'ol/geom/MultiLineString';
import { Map as OlMap } from 'ol'
import { defaultStyle, selectedStyle, topStyle } from '../WebMercatorMap/utilities ';
import * as turf from '@turf/turf';
import axios from 'axios';
import { FeatureCollection } from 'geojson';
import { GeoJsonProperties } from 'geojson';
import { Extent } from 'ol/extent';
import { transform } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';

const overpassEndpoint = 'https://overpass-api.de/api/interpreter';
const logCoordinates = (geoJsonData: FeatureCollection, message: string) => {
    const firstFeature = geoJsonData.features[0];
    const geometry = firstFeature.geometry;
  
    if (geometry.type === 'LineString' || geometry.type === 'MultiLineString') {
      console.log(`${message} coordinates:`, geometry.coordinates[0][0]);
    } else {
      console.warn(`${message} geometry is not a LineString or MultiLineString, so coordinates cannot be logged.`);
    }
  };

  
  
  const reprojectGeoJSON = (geojson: FeatureCollection, sourceProjection: string, targetProjection: string) => {
    geojson.features.forEach((feature) => {
      const geometry = feature.geometry;
      if (geometry.type === 'LineString') {
        geometry.coordinates = geometry.coordinates.map(coord =>
          transform(coord as [number, number], sourceProjection, targetProjection)
        );
      } else if (geometry.type === 'MultiLineString') {
        geometry.coordinates = geometry.coordinates.map(line =>
          line.map(coord =>
            transform(coord as [number, number], sourceProjection, targetProjection)
          )
        );
      }
    });
  
    return geojson;
  };
  
  export const fetchStreetsInSantaBarbara = async (): Promise<FeatureCollection> => {
    const query = `
      [out:json];
      area[name="Santa Barbara County"]->.searchArea;
      (
        way["highway"~"^(motorway|trunk|primary|secondary|tertiary|unclassified|residential|service|living_street)$"](area.searchArea);
      );
      out geom;
    `;
  
    try {
      const response = await axios.post(overpassEndpoint, `data=${query}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
  
      // Group coordinates by street name
      const groupedStreets: { [key: string]: GeoJSON.Position[][] } = {};
  
      response.data.elements.forEach((element: any) => {
        const name = element.tags.name || "Unnamed Street";
        if (!groupedStreets[name]) {
          groupedStreets[name] = [];
        }
        groupedStreets[name].push(element.geometry.map((coord: any) => [coord.lon, coord.lat]));
      });
  
      // Construct the GeoJSON data, merging lines with the same name into MultiLineString
      const geoJsonData: FeatureCollection = {
        type: "FeatureCollection",
        features: Object.entries(groupedStreets).map(([name, lines]) => ({
          type: "Feature",
          geometry: {
            type: "MultiLineString",
            coordinates: lines,
          },
          properties: { name: name },
        })),
      };
  
      // Log original coordinates
      logCoordinates(geoJsonData, "Original");
  
      // Reproject the data from EPSG:4326 to EPSG:3857
      const reprojectedGeoJsonData = reprojectGeoJSON(geoJsonData, 'EPSG:4326', 'EPSG:3857');
  
      // Log reprojected coordinates
      logCoordinates(reprojectedGeoJsonData, "Reprojected");
  
      return reprojectedGeoJsonData;
  
    } catch (error) {
      console.error("Error fetching streets from OSM:", error);
      throw error;
    }
  };
  
  export const calculateStats = (selectedFeatures: Feature<Geometry>[]): { count: number, avgLength: number, conformSpacings: number, pctConformity: number, max: number } => {
    if (selectedFeatures.length === 0) {
      // Return an empty stats object with default values
      return { count: 0, avgLength: 0, conformSpacings: 0, pctConformity: 0, max: 0 };
    }
  
    const totalLength = selectedFeatures.reduce((sum, feature) => sum + parseFloat(feature.get('length') || '0'), 0);
    const count = selectedFeatures.length;
    const max = Math.max(...selectedFeatures.map((feature) => parseFloat(feature.get('length') || '0')));
    const conformSpacings = count - selectedFeatures.reduce((sum, feature) => sum + parseFloat(feature.get('non_compli') || '0'), 0);
    const pctConformity = count > 0 ? (conformSpacings / count) * 100 : 0;
    const avgLength = count > 0 ? totalLength / count : 0;
  
    return { count, avgLength, conformSpacings, pctConformity, max };
  };
  
  

export const selectByArea = (
  layers: VectorLayer<VectorSource<Feature<Geometry>>>[],
  geometry: Polygon,
  excludeHighway: boolean
): { count: number, avgLength: number, conformSpacings: number, pctConformity: number, max: number } | null => {
  console.log('Select by area:', geometry);
  const mainLayer = layers[3];
  const source = mainLayer.getSource();
  if (source) {
        let allFeatures = source.getFeatures();
        if (excludeHighway) {
          allFeatures = allFeatures.filter((feature) => feature.get('highway') === 'other' || feature.get('highway') === 'highway');
          console.log('excluding highway')
        }
    // const allFeatures = source.getFeatures();
    allFeatures.forEach((feature) => {
      const intersects = feature.get('intersects');
      if (intersects !== 1) { // Only reset style for non-intersected features
        feature.setStyle(defaultStyle);
      }
      else {
        feature.setStyle(undefined);
      }
    });

    let selectedFeatures: Feature<Geometry>[] = [];
    const polygonFeature = turf.polygon([geometry.getCoordinates()[0]]); {/* Convert to a geojson*/}

    if (geometry instanceof Polygon) {
        selectedFeatures = allFeatures.filter((feature) => {
          const geom = feature.getGeometry();
          
          if (geom instanceof Polygon) {
            const featurePolygon = turf.polygon([geom.getCoordinates()[0].map(coord => coord.map(c => +c.toFixed(6)))]);
            const intersects = turf.booleanIntersects(polygonFeature, featurePolygon);
            // console.log(`Polygon intersects: ${intersects}`, feature);
            return intersects;
          } else if (geom instanceof LineString) {
            const featureLine = turf.lineString(geom.getCoordinates().map(coord => coord.map(c => +c.toFixed(6))));
            const intersects = turf.booleanIntersects(polygonFeature, featureLine);
            // console.log(`LineString intersects: ${intersects}`, feature);
            return intersects;
          } else if (geom instanceof MultiLineString) {
            const featureMultiLine = turf.multiLineString(geom.getCoordinates().map(line => line.map(coord => coord.map(c => +c.toFixed(6)))));
            const intersects = turf.booleanIntersects(polygonFeature, featureMultiLine);
            // console.log(`MultiLineString intersects: ${intersects}`, feature);
            return intersects;
          } else if (geom instanceof Point) {
            const featurePoint = turf.point(geom.getCoordinates().map(c => +c.toFixed(6)));
            const contains = turf.booleanContains(polygonFeature, featurePoint);
            // console.log(`Point contained: ${contains}`, feature);
            return contains;
          }
        
          return false;
        });
    } else {
      selectedFeatures = allFeatures.filter((feature) => {
        const geom = feature.getGeometry();
        return geom && geom.intersectsExtent(geometry);
      });
    }

    selectedFeatures.forEach((feature) => feature.setStyle(topStyle));
    if (selectedFeatures.length === 0) {
        return null;  // Return null explicitly if no features are selected
      }
    const totalLength = selectedFeatures.reduce((sum, feature) => sum + parseFloat(feature.get('length')), 0);
    const avgLength = selectedFeatures.length > 0 ? totalLength / selectedFeatures.length : 0;
    console.log(`Average length of selected features: ${avgLength.toFixed(2)} feet`);
    const stats = calculateStats(selectedFeatures);
        return stats || null;
  }
  return null; 
};

export const selectByStreet = async (
  layers: VectorLayer<VectorSource<Feature<Geometry>>>[],
  streetName: string,
  streetData: FeatureCollection<GeoJSON.Geometry, GeoJsonProperties>,
  map: OlMap,
  excludeHighway: boolean
): Promise<{ count: number, avgLength: number, conformSpacings: number, pctConformity: number, max: number } | null> => {
  console.log(`Select by street name: ${streetName}`);

  const targetStreet = streetData.features.find(
      (feature: any) => feature.properties?.name?.toLowerCase() === streetName.toLowerCase()
  );

  if (!targetStreet) {
      console.warn(`Street with name "${streetName}" not found.`);
      return null;
  }

  const targetStreetGeometry = targetStreet.geometry as GeoJSON.Geometry;
  if (targetStreetGeometry.type !== 'MultiLineString') {
      console.warn('Target street geometry is not a MultiLineString.');
      return null;
  }

  const coordinates4326 = targetStreetGeometry.coordinates.map(line =>
      line.map(coord => transform(coord as [number, number], 'EPSG:3857', 'EPSG:4326'))
  );

  const targetLine = turf.multiLineString(coordinates4326 as GeoJSON.Position[][]);
  const bufferedTargetLine = turf.buffer(targetLine, 10, { units: 'meters' });

  if (!bufferedTargetLine || 
      bufferedTargetLine.geometry.type !== 'Polygon' && 
      bufferedTargetLine.geometry.type !== 'MultiPolygon') {
      console.warn('Buffer operation resulted in an invalid geometry.');
      return null;
  }

  if (bufferedTargetLine.geometry.type === 'Polygon' || bufferedTargetLine.geometry.type === 'MultiPolygon') {
      bufferedTargetLine.geometry.coordinates = bufferedTargetLine.geometry.coordinates.map(polygon =>
          polygon.map(ring => {
              // Ensure the polygon is properly closed
              
              if (
                ring.length > 0 &&
                Array.isArray(ring[0]) &&
                Array.isArray(ring[ring.length - 1]) &&
                JSON.stringify(ring[0]) !== JSON.stringify(ring[ring.length - 1])
            ) {
              const ring = polygon.map((coord) => coord as [number, number]);

              // Extract the first coordinate and ensure it is typed correctly
              const firstCoordinate: [number, number] = ring[0];
              
              // Push the first coordinate back into the ring
              ring.push(firstCoordinate);
            }
                      return ring.map(coord => transform(coord as [number, number], 'EPSG:4326', 'EPSG:3857'));
          })
      );
  } else {
      console.warn('Buffer operation resulted in an invalid geometry.');
      return null;
  }

  const extent: Extent = turf.bbox(bufferedTargetLine as turf.AllGeoJSON) as Extent;

  console.log("Buffered target line:", bufferedTargetLine);
  console.log("line before buffering:, targetStreetGeometry ")

  if (extent[0] < -20026376.39 || extent[2] > 20026376.39 || extent[1] < -20048966.10 || extent[3] > 20048966.10) {
      console.warn('The extent appears to be outside the valid range for EPSG:3857');
  } else {
      console.log('Zooming to street:', streetName);
      map.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
  }

  const mainLayer = layers[3];
  const source = mainLayer.getSource();

  if (source) {
      let allFeatures = source.getFeatures();
      if (excludeHighway) {
        allFeatures = allFeatures.filter((feature) => feature.get('highway') === 'other' || feature.get('highway') === 'highway');
      } else {
          allFeatures = source.getFeatures();
      }
      allFeatures.forEach((feature) => {
        const intersects = feature.get('intersects');
        if (intersects !== 1) { // Only reset style for non-intersected features
          feature.setStyle(defaultStyle);
        }
        else {
          feature.setStyle(undefined);
        }
      });

      const selectedFeatures = allFeatures.filter((feature) => {
        const geom = feature.getGeometry();
      
        if (geom instanceof LineString) {
          const lineCoords = geom.getCoordinates();
          const line = turf.lineString(lineCoords as GeoJSON.Position[]);
          // console.log('Checking intersection with line:', line);
          const intersections = bufferedTargetLine && turf.booleanIntersects(bufferedTargetLine.geometry as GeoJSON.Geometry, line.geometry as GeoJSON.Geometry);
          // console.log('Intersection result:', intersections);
          return intersections;
        } else if (geom instanceof MultiLineString) {
          const lineCoords = geom.getCoordinates() as GeoJSON.Position[][];
          for (const lineCoord of lineCoords) {
            const line = turf.lineString(lineCoord);
            // console.log('Checking intersection with line segment:', line);
            const intersections = bufferedTargetLine && turf.booleanIntersects(bufferedTargetLine.geometry as GeoJSON.Geometry, line.geometry as GeoJSON.Geometry);
            // console.log('Intersection result for line segment:', intersections);
            if (intersections) {
              return true;
            }
          }
        }
        return false;
      });
      
      selectedFeatures.forEach((feature) => feature.setStyle(topStyle));

      if (selectedFeatures.length === 0) {
          console.warn('No features selected by the street buffer.');
          return null;
      }

      const totalLength = selectedFeatures.reduce((sum, feature) => sum + parseFloat(feature.get('length')), 0);
      const avgLength = selectedFeatures.length > 0 ? totalLength / selectedFeatures.length : 0;
      console.log(`Average length of selected features: ${avgLength.toFixed(2)} feet`);
      const stats = calculateStats(selectedFeatures);
      return stats || null;
  }

  return null;
};


export const selectByHydrant = (
  layers: VectorLayer<VectorSource<Feature<Geometry>>>[],
  hydrantId: number,
  excludeHighway: boolean
) : { count: number, avgLength: number, conformSpacings: number, pctConformity: number, max: number } | null => {
  console.log(`Select by hydrant ID: ${hydrantId}`);
  
  if (layers.length === 0) {
    console.warn('No layers provided');
    return null;
  }
  
  const mainLayer = layers[3];
  const source = mainLayer.getSource();

  if (source) {
    let allFeatures = source.getFeatures();
    if (excludeHighway) {
      allFeatures = allFeatures.filter((feature) => feature.get('highway') === 'other' || feature.get('highway') === 'highway');
    }
    // Reset all features to default style
    allFeatures.forEach((feature) => {
      const intersects = feature.get('intersects');
      if (intersects !== 1) { // Only reset style for non-intersected features
        feature.setStyle(defaultStyle);
      }
      else {
        feature.setStyle(undefined);
      }
    });

    // Filter features by hydrantId
    const selectedFeatures = allFeatures.filter((feature) => {
      const sourceId = parseFloat(feature.get('source'));
      const targetId = parseFloat(feature.get('target'));
      return sourceId === hydrantId || targetId === hydrantId;
    });

    // Apply selected style to matching features
    selectedFeatures.forEach((feature) => feature.setStyle(topStyle));

    if (selectedFeatures.length === 0) {
        return null;  // Return null explicitly if no features are selected
      }

    // Calculate and log average length
    const totalLength = selectedFeatures.reduce((sum, feature) => sum + parseFloat(feature.get('length')), 0);
    const avgLength = selectedFeatures.length > 0 ? totalLength / selectedFeatures.length : 0;
    console.log(`Average length of selected features: ${avgLength.toFixed(2)} feet`);
    const stats = calculateStats(selectedFeatures);
    return stats||null;
  } else {
    console.warn('Source not found in main layer');
  }
  return null; 
};
